// auth action types

export const USER_DATA = "USER_DATA";
export const GET_USER_PROFILE_DATA = "GET_USER_PROFILE_DATA";
export const CHANGE_USER_CURRENCY = "CHANGE_USER_CURRENCY";
export const GET_WORKERS = "GET_WORKERS";
export const GET_AD_POSTS = "GET_AD_POSTS";
export const CLEAR_AD_POSTS = "CLEAR_AD_POSTS";
export const CHANGE_LOGIN_MODAL_DISPLAY = "CHANGE_LOGIN_MODAL_DISPLAY";
export const ADD_NEW_USER_RATE_ID = "ADD_NEW_USER_RATE_ID";
export const GET_ALL_RATED_IDS = "GET_ALL_RATED_IDS";

// cart action types
export const ADD_ARTWORK_IN_CART = "ADD_ARTWORK_IN_CART";

export const GET_TRANSACTION_URL = "GET_TRANSACTION_URL";
export const GET_MY_QRS = "GET_MY_QRS";
export const GET_INFO_FOR_FIND_PET = "GET_INFO_FOR_FIND_PET";
export const DELETE_MY_QR = "DELETE_MY_QR";

export const SERVER_ERROR = "SERVER_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const CHANGE_SIGNUP_MODAL_DISPLAY = "CHANGE_SIGNUP_MODAL_DISPLAY";

export const ADD_NEW_POST = "ADD_NEW_POST";
export const GET_POSTS = "GET_POSTS";
export const POSTS_FOR_CONTAINER = "POSTS_FOR_CONTAINER";
export const GET_SINGLE_POST = "GET_SINGLE_POST";
export const DELETE_SINGLE_POST = "DELETE_SINGLE_POST";
export const GET_SAVED_POSTS = "GET_SAVED_POSTS";
export const SAVE_POST = "SAVE_POST";
export const GET_SAVED_POSTS_FOR_FAVORITE_PAGE =
  "GET_SAVED_POSTS_FOR_FAVORITE_PAGE";
export const DELETE_SAVED_POST = "DELETE_SAVED_POST";
export const GET_ACTIVE_POSTS = "GET_ACTIVE_POSTS";
export const GET_CURRANCY_RATE = "GET_CURRANCY_RATE";
export const GET_SINGEL_ACTIVE_POST = "GET_SINGEL_ACTIVE_POST";

// CHAT ACTIONS

export const GET_CHAT_USERS = "GET_CHAT_USERS";
export const GET_CHAT_USER_MESSAGES = "GET_CHAT_USER_MESSAGES";
export const ADD_NEW_CHAT_USER = "ADD_NEW_CHAT_USER";
export const CLEAR_CHAT_USERS = "CLEAR_CHAT_USERS";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const CHANGE_CURRENT_CHAT_USER_ID = "CHANGE_CURRENT_CHAT_USER_ID";

// MANAGEMENT ACTONS
export const GET_BANNERS = "GET_BANNERS";


export const GET_OVERALL_STATISTICS = "GET_OVERALL_STATISTICS";