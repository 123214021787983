import axiosInstance from "../axiosApi/api";
import { handleServerError } from "./errors";
import { DELETE_MY_QR, GET_INFO_FOR_FIND_PET, GET_MY_QRS, GET_OVERALL_STATISTICS } from "./types";
import { generate_unique_file_names } from "../utils.js";

export const getTransactionUrl = (formData, order_type) => {
  return async (dispatch) => {
    try {
      let pet_image = null;
      if (formData.pet_image) {

        pet_image = formData.pet_image[0];
        formData.pet_image = generate_unique_file_names(pet_image, true);
      }
      const currentLang = localStorage.getItem("i18nextLng").toUpperCase();
      const res = await axiosInstance.post(
        `/orders/transaction/url/${currentLang}/${order_type}/`,
        formData,
      );
      if (res.data.extra_data !== null && res.data.extra_data.pre_signed_url !== null) {
        await fetch(res.data.extra_data.pre_signed_url, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: pet_image,
        });
      }
      window.open(res.data.transaction_url, "_self");
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const getMyQrsAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get("/orders/my/qrs/");
      dispatch({
        type: GET_MY_QRS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
};

export const deleteQR = (qrID) => {
  return async (dispatch) => {
    try {
      await axiosInstance.delete(`/orders/my/qrs/${qrID}/`);
      dispatch({
        type: DELETE_MY_QR,
        payload: qrID
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
}

export const updateQR = (qrID, formData) => {
  return async (dispatch) => {
    try {
      await axiosInstance.put(`/orders/my/qrs/${qrID}/`, formData);
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
}

export const changeQRMarkedAsLost = (qrID) => {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`/orders/my/qrs/${qrID}/`);
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
}

export const getFindPetInfo = (qrID) => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`/orders/qr/${qrID}/`);
      dispatch({
        type: GET_INFO_FOR_FIND_PET,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
}


export const getOverallStatisticsAction = () => {
  return async (dispatch) => {
    try {
      const res = await axiosInstance.get(`/orders/static/`);
      dispatch({
        type: GET_OVERALL_STATISTICS,
        payload: res.data,
      });
    } catch (e) {
      handleServerError(dispatch, e);
    }
  };
}